
import { Component, Vue } from "vue-property-decorator";

import PowerPlantsTree from "@/components/Core/Tree/PowerPlants/Tree.vue";

@Component({
  components: {
    PowerPlantsTree,
  },
})
export default class PowerPlantsNuclearUnits extends Vue {}
