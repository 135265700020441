
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IGenerationService } from "@/services";

import { GenerationInfos } from "@/domain";

@Component
export default class GenerationAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  generationIdToInclude!: string | null;

  @Prop({ required: false, default: false })
  includeAll!: boolean;

  get generationService() {
    return this.container.resolve<IGenerationService>(S.GENERATION_SERVICE);
  }

  get filteredGenerations() {
    const filtered = _.chain(this.generations)
      .filter((g) => !g.disabled || g.id == this.generationIdToInclude)
      .orderBy((o) => o.name, "asc")
      .map((g) => {
        return {
          id: g.id,
          name: g.name,
        };
      })
      .value();
    if (this.includeAll) {
      filtered.splice(0, 0, { id: "all", name: "Toutes" });
    }
    return filtered;
  }

  generations: GenerationInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.generations = await this.generationService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }
}
