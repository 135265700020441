var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(`Édition du site ${_vm.item.name}`)+" ")]),_c('v-card-title',[_vm._v("Site")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"Nom du site","rules":[
              (v) =>
                !!v || 'Ce champ est requis pour ajouter un nouveau site',
              (v) =>
                (!!v && !_vm.isNameAllowed(v)) ||
                'Ce nom de site est déjà existant',
            ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',[_vm._v("mdi-map-marker")])]},proxy:true}]),model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Trigramme","rules":[
              (v) =>
                !!v || 'Ce champ est requis pour ajouter un nouveau site',
              (v) =>
                (!!v && !_vm.isTrigramAllowed(v)) ||
                'Ce trigramme est déjà existant pour un site',
            ]},model:{value:(_vm.trigram),callback:function ($$v) {_vm.trigram=$$v},expression:"trigram"}})],1)],1),_c('div',{staticClass:"ml-15"},[_c('span',{staticClass:"custom-title"},[_vm._v("Tranches")]),_vm._l((_vm.nuclearUnits),function(n,index){return _c('nuclear-unit-update',{key:index,attrs:{"index":index,"names":_vm.numbers,"value":n},on:{"remove":function($event){return _vm.deleteNuclearUnit(index)},"input":function($event){return _vm.updateNuclearUnits($event)}}})})],2),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"text":"","outlined":"","color":"blue darken-4"},on:{"click":function($event){return _vm.addNuclearUnit()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Ajouter une tranche ")],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Fermer")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":"","color":"success","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v("Valider")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }