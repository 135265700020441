
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";

import { SERVER_CONFIG } from "@/config/literals";

import { LITE_FEATURE } from "@/domain";
import { ServerConfig } from "prometheus-synced-ui";

import { Feature } from "vue-feature-flags";
import UpdateDisabledButton from "@/components/General/UpdateDisabledButton.vue";

@Component({
  components: {
    UpdateDisabledButton,
    Feature,
  },
  data: () => {
    return {
      LITE_FEATURE
    }
  }
})
export default class ChildrenActionsUI extends Vue {
  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;

  @Prop({ required: true })
  service!: any;

  @Prop({ required: true })
  child!: any;

  loading: boolean = false;

  async updateDisabled() {
    try {
      this.loading = true;
      const powerPlantTree = await this.service.updateStatus(this.child.id, {
        disabled: !this.child.disabled,
      });
      this.$emit("update-disabled", powerPlantTree);
    } finally {
      this.loading = false;
    }
  }
}
