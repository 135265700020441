
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class GlobalActions extends Vue {
  @Prop({ required: true })
  showDisabled!: boolean;

  @Prop({ required: true })
  component!: any;

  @Prop({ required: false, default: () => 900 })
  maxWidth!: number;

  createDialog: boolean = false;

  create(element: any) {
    this.$emit("create", element);
    this.createDialog = false;
  }
}
