
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { PROVIDER, SERVICES as S } from "@/config/literals";
import { DependencyContainer } from "tsyringe";

import GlobalActions from "@/components/Core/Tree/GlobalActions.vue";
import PowerPlantUpdate from "@/components/Core/Update/Localisation/PowerPlantUpdate.vue";
import PowerPlantsTreeView from "@/components/Core/Tree/PowerPlants/TreeView.vue";
import Loading from "@/views/Loading.vue";

import {
  IGenerationService,
  INuclearUnitService,
  IPowerPlantService,
} from "@/services";

import { GenerationInfos, PowerPlantDetails } from "@/domain";
import { NuclearUnitInfos, PowerPlantInfos } from "prometheus-synced-ui";

@Component({
  components: {
    Loading,
    GlobalActions,
    PowerPlantUpdate,
    PowerPlantsTreeView,
  },
})
export default class PowerPlantsTree extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get powerPlantService() {
    return this.container.resolve<IPowerPlantService>(S.POWER_PLANT_SERVICE);
  }

  get nuclearUnitService() {
    return this.container.resolve<INuclearUnitService>(S.NUCLEAR_UNIT_SERVICE);
  }

  get generationService() {
    return this.container.resolve<IGenerationService>(S.GENERATION_SERVICE);
  }

  get existingPowerPlantsName() {
    return _.map(this.powerPlants, (p) => p.name);
  }

  get existingPowerPlantsTrigram() {
    return _.map(this.powerPlants, (p) => p.trigram);
  }

  get createComponent(): any {
    return () =>
      import("@/components/Core/Create/Localisation/PowerPlantCreate.vue");
  }

  powerPlants: PowerPlantInfos[] = [];
  nuclearUnits: NuclearUnitInfos[] = [];
  generations: GenerationInfos[] = [];
  updateDialog: boolean = false;
  loading: boolean = false;
  showDisabled: boolean = false;
  selectedPowerPlant: PowerPlantDetails | null = null;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;

      this.powerPlants = await this.powerPlantService.getMany({
        ids: [],
        nuclearUnitIds: [],
        includeEnabled: true,
        includeDisabled: true,
      });

      this.nuclearUnits = await this.nuclearUnitService.getMany({
        ids: [],
        powerPlantIds: [],
        includeEnabled: true,
        includeDisabled: true,
      });

      this.generations = await this.generationService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  openUpdateDialog(plant: PowerPlantDetails) {
    this.selectedPowerPlant = plant;
    this.updateDialog = true;
  }

  createPowerPlant(powerPlant: PowerPlantDetails) {
    this.powerPlants.push(powerPlant.toInfos());
    this.nuclearUnits.push(...powerPlant.nuclearUnits);
  }

  createNuclearUnit(nuclearUnit: NuclearUnitInfos) {
    this.nuclearUnits.push(nuclearUnit);
  }

  updateNuclearUnit(nuclearUnit: NuclearUnitInfos) {
    const index = _.findIndex(this.nuclearUnits, (n) => n.id == nuclearUnit.id);
    if (index != -1) {
      this.nuclearUnits.splice(index, 1, nuclearUnit);
    }
  }

  updatePowerPlant(powerPlant: PowerPlantInfos) {
    const index = _.findIndex(this.powerPlants, (p) => p.id == powerPlant.id);
    if (index != -1) {
      this.powerPlants.splice(index, 1, powerPlant);
    }
  }

  updatePlantAndUnits(powerPlant: PowerPlantDetails) {
    this.updatePowerPlant(powerPlant.toInfos());

    powerPlant.nuclearUnits.forEach((n) => {
      this.updateNuclearUnit(n);
    });

    this.updateDialog = false;
  }
}
