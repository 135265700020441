
import { Component, Vue, Inject, Prop, Ref } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import NuclearUnitUpdate from "@/components/Core/Update/Localisation/NuclearUnitUpdate.vue";

import { PowerPlantDetails } from "@/domain";

import { INuclearUnitService, IPowerPlantService } from "@/services";

@Component({
  components: {
    NuclearUnitUpdate,
  },
})
export default class PowerPlantUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Ref("form")
  form!: any;

  @Prop({ required: true })
  item!: PowerPlantDetails;

  @Prop({ required: true })
  forbiddenNames!: string[];

  @Prop({ required: true })
  forbiddenTrigrams!: string[];

  get powerPlantService() {
    return this.container.resolve<IPowerPlantService>(S.POWER_PLANT_SERVICE);
  }
  get nuclearUnitService() {
    return this.container.resolve<INuclearUnitService>(S.NUCLEAR_UNIT_SERVICE);
  }
  get numbers() {
    return _.chain(this.nuclearUnits)
      .map((n) => n.number)
      .filter((n) => n != null)
      .value();
  }

  loading: boolean = false;
  name: string | null = null;
  trigram: string | null = null;
  nuclearUnits: {
    id: string | null;
    number: number | null;
    generationId: string | null;
    disabled: boolean;
  }[] = [];
  valid: boolean = true;

  mounted() {
    this.name = this.item.name;
    this.trigram = this.item.trigram;

    this.item.nuclearUnits.forEach((n) => {
      this.nuclearUnits.push({
        id: n.id,
        number: n.number,
        generationId: n.generationId,
        disabled: n.disabled,
      });
    });

    this.nuclearUnits = _.chain(this.nuclearUnits)
      .cloneDeep()
      .orderBy((n) => n.number, "asc")
      .value();
  }

  addNuclearUnit() {
    this.nuclearUnits.push({
      id: null,
      number: null,
      generationId: null,
      disabled: false,
    });
  }

  deleteNuclearUnit(index: number) {
    this.nuclearUnits.splice(index, 1);
  }

  updateNuclearUnits(value: {
    index: number;
    generationId: string | null;
    number: number | null;
  }) {
    const unit = {
      number: value.number,
      generationId: value.generationId,
      id: this.nuclearUnits[value.index].id,
      disabled: this.nuclearUnits[value.index].disabled,
    };
    this.nuclearUnits.splice(value.index, 1, unit);
  }

  isNameAllowed(name: string) {
    return (
      _.findIndex(
        this.forbiddenNames,
        (v) => v.toUpperCase() == name.toUpperCase()
      ) != -1 && this.item.name.toUpperCase() != name.toUpperCase()
    );
  }

  isTrigramAllowed(trigram: string) {
    return (
      _.findIndex(
        this.forbiddenTrigrams,
        (v) => v.toUpperCase() == trigram.toUpperCase()
      ) != -1 && this.item.trigram.toUpperCase() != trigram.toUpperCase()
    );
  }

  async submit() {
    try {
      this.loading = true;
      if (this.form.validate()) {
        const updatedNuclearUnit = _.filter(
          this.nuclearUnits,
          (n) => n.id != null
        );
        const existingNuclearUnits = _.map(updatedNuclearUnit, (n) => {
          return {
            id: n.id!,
            number: n.number!,
            generationId: n.generationId!,
            disabled: n.disabled,
          };
        });

        const powerPlantUpdated = await this.powerPlantService.update(
          this.item.id,
          {
            name: this.name!,
            trigram: this.trigram!,
            nuclearUnits: existingNuclearUnits,
            disabled: this.item.disabled,
          }
        );
        const createdNuclearUnit = _.filter(
          this.nuclearUnits,
          (n) => n.id == null
        );
        if (createdNuclearUnit.length != 0) {
          const nuclearUnitCreated = await this.nuclearUnitService.create(
            _.map(createdNuclearUnit, (n) => {
              return {
                number: n.number!,
                generationId: n.generationId!,
                powerPlantId: this.item.id!,
              };
            })
          );
          powerPlantUpdated.nuclearUnits.push(...nuclearUnitCreated);
          nuclearUnitCreated.forEach((n) => {
            this.$emit("create-nuclear-unit", n);
          });
        }
        this.$emit("update", powerPlantUpdated);
      }
    } finally {
      this.loading = false;
    }
  }
}
