
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import ParentsActionsUi from "@/components/Core/Tree/Parents/ParentsActionsUI.vue";
import ChildrenActionsUi from "@/components/Core/Tree/Children/ChildrenActionsUI.vue";

import { PowerPlantDetails, PowerPlantTree } from "@/domain";
import { NuclearUnitInfos, PowerPlantInfos } from "prometheus-synced-ui";

import { INuclearUnitService, IPowerPlantService } from "@/services";

@Component({
  components: {
    ParentsActionsUi,
    ChildrenActionsUi,
  },
})
export default class TreeElement extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: {
    id: string;
    name: string;
    type: string;
    disabled: boolean;
  };

  @Prop({ required: true })
  powerPlants!: PowerPlantInfos[];

  @Prop({ required: true })
  nuclearUnits!: NuclearUnitInfos[];

  get nuclearUnitService() {
    return this.container.resolve<INuclearUnitService>(S.NUCLEAR_UNIT_SERVICE);
  }

  get powerPlantService() {
    return this.container.resolve<IPowerPlantService>(S.POWER_PLANT_SERVICE);
  }

  getNuclearUnit(id: string) {
    return _.find(this.nuclearUnits, (u) => u.id == id)!;
  }

  getPowerPlant(id: string) {
    const powerPlant = _.find(this.powerPlants, (p) => p.id == id)!;
    const nuclearUnits = _.filter(
      this.nuclearUnits,
      (u) => u.powerPlantId == id
    );
    return new PowerPlantDetails({
      id: powerPlant.id,
      name: powerPlant.name,
      trigram: powerPlant.trigram,
      nuclearUnits: nuclearUnits,
      disabled: powerPlant.disabled,
    });
  }

  update(powerPlantTree: PowerPlantTree) {
    this.$emit("update-power-plant", powerPlantTree.toInfos());

    let nuclearUnits: NuclearUnitInfos[] = _.map(
      powerPlantTree.nuclearUnits,
      (n) => {
        return {
          id: n.id,
          number: n.number,
          generationId: n.generation.id,
          powerPlantId: powerPlantTree.id,
          systemIds: _.map(n.systems, (s) => s.id),
          numberLabel: n.numberLabel,
          disabled: n.disabled,
        };
      }
    );

    nuclearUnits.forEach((n) => {
      this.$emit("update-nuclear-unit", n);
    });
  }
}
