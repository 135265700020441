
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import { toUnitNumber } from "@/tools";

import PowerPlantTreeElement from "@/components/Core/Tree/PowerPlants/TreeElement.vue";
import TreeActions from "@/components/Core/Tree/TreeActions.vue";

import { GenerationInfos } from "@/domain";
import { PowerPlantInfos, NuclearUnitInfos } from "prometheus-synced-ui";

@Component({
  components: {
    PowerPlantTreeElement,
    TreeActions,
  },
  filters: {
    prettyPlant(item: { name: string; trigram: string }): string {
      return `${item.name} (${item.trigram})`;
    },
    prettyUnit(item: { number: number; generation: GenerationInfos }): string {
      return `${toUnitNumber(item.number)} (${item.generation.name})`;
    },
  },
})
export default class PowerPlantsTreeView extends Vue {
  @Prop({ required: true })
  powerPlants!: PowerPlantInfos[];

  @Prop({ required: true })
  nuclearUnits!: NuclearUnitInfos[];

  @Prop({ required: true })
  generations!: GenerationInfos[];

  @Prop({ required: true })
  showDisabled!: boolean;

  get powerPlantsFiltered() {
    return (
      (this.showDisabled && this.powerPlants) ||
      _.filter(this.powerPlants, (p) => !p.disabled)
    );
  }

  get nuclearUnitsFiltered() {
    return (
      (this.showDisabled && this.nuclearUnits) ||
      _.filter(this.nuclearUnits, (u) => !u.disabled)
    );
  }

  get powerPlantTree() {
    return _.chain(this.powerPlantsFiltered)
      .orderBy((p) => p.trigram, "asc")
      .map((p) => {
        return {
          id: p.id,
          name: p.name,
          trigram: p.trigram,
          type: "powerPlant",
          disabled: p.disabled,
          children: _.chain(this.nuclearUnitsFiltered)
            .filter((u) => u.powerPlantId == p.id)
            .orderBy((n) => n.number, "asc")
            .map((n) => {
              return {
                id: n.id,
                number: n.number,
                type: "nuclearUnit",
                powerPlantId: n.powerPlantId,
                generation: _.find(
                  this.generations,
                  (g) => g.id == n.generationId
                )!,
                disabled: n.disabled,
              };
            })
            .value(),
        };
      })
      .value();
  }

  isOpened: string[] = [];
  toOpen: string[] = [];

  handleFolding(openAction: boolean) {
    this.toOpen = openAction ? _.map(this.powerPlants, (g) => g.id) : [];
  }
}
