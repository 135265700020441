
import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";

import { toNullableNumber, isInteger } from "@/tools";

import GenerationAutocomplete from "@/components/General/Autocompletes/Localisation/GenerationAutocomplete.vue";

@Component({
  components: {
    GenerationAutocomplete,
  },
  data: () => {
    return {
      toNullableNumber,
      isInteger,
    };
  },
})
export default class NuclearUnitUpdate extends Vue {
  @Prop({ required: true })
  value!: {
    id: string | null;
    number: number | null;
    generationId: string | null;
    disabled: boolean;
  };

  @Prop({ required: true })
  names!: number[];

  @Prop({ required: true })
  index!: number;

  get checkNameRule() {
    let unit = _.cloneDeep(this.value);
    if (unit.number != null) {
      const index = _.findIndex(this.names, (n) => n == unit.number);
      const filteredNames = _.cloneDeep(this.names);
      filteredNames.splice(index, 1);
      return _.findIndex(filteredNames, (n) => n == unit.number) == -1
        ? true
        : "Ce numéro est déjà attribué";
    } else {
      return true;
    }
  }

  generationId: string | null = null;

  mounted() {
    this.generationId = _.cloneDeep(this.value).generationId;
  }

  onNumberChange(number: number | null) {
    let newSystem = _.cloneDeep(this.value);
    newSystem.number = number;
    this.$emit("input", {
      index: this.index,
      number: newSystem.number,
      generationId: newSystem.generationId,
    });
  }

  onGenerationIdChange(generationId: string | null) {
    let newSystem = _.cloneDeep(this.value);
    newSystem.generationId = (generationId && generationId) || "";
    this.$emit("input", {
      index: this.index,
      number: newSystem.number,
      generationId: newSystem.generationId,
    });
  }
}
